<template>
  <div>
    <b-container class="mt-5">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <h3>Login</h3>

          <b-card class="mb-3">
            <b-form @submit.prevent="login">
              <b-form-group
                id="emailGroup"
                label="Email address"
                label-for="emailInput"
              >
                <b-form-input
                  id="emailInput"
                  type="email"
                  autocorrect="off"
                  autocapitalize="none"
                  v-model.trim="form.email"
                  required
                  placeholder="email@domain"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="passwordGroup"
                label="Password"
                label-for="passwordInput"
              >
                <b-form-input
                  id="passwordInput"
                  type="password"
                  v-model="form.password"
                  required
                  placeholder="password"
                ></b-form-input>
              </b-form-group>
              <b-button
                size="lg"
                block
                class="mb-3"
                type="submit"
                variant="primary"
                :disabled="loggingIn"
              >
                Log In
                <b-spinner small v-if="loggingIn" />
              </b-button>
            </b-form>

            <b-button class="my-3" @click="resetPassword" variant="link">forgot password</b-button>
          </b-card>
          <div v-if="!loggedInBefore || loggedInBefore === null">
            <h4 class="text-center my-3">
              If you have signed up, you must begin at the link which was
              emailed to you.  Thank you.
            </h4>
            <h4 class="text-center my-3">
              If you haven't received the link, and you have signed up, please
              contact support@plainwealth.io
            </h4>
          </div>
        </div>
      </div>
    </b-container>

    <b-modal
      id="modal-code-prompt"
      ref="modal"
      title="Enter 6-digit Code"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      v-model="showModal"
    >
      <form ref="formcode" @submit.stop.prevent="handleCodeSubmit">
        <b-form-group
          label="Code"
          label-for="code-input"
          invalid-feedback="Code must be six digits.  Code is invalid."
          :state="codeState"
        >
          <b-form-input
            id="code-input"
            v-model="code"
            :state="codeState"
            required
            type="text"
            pattern="[0-9]{6}"
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loggingIn: false,
      loggedInBefore: null,
      form: {
        email: "",
        password: "",
      },
      showModal: false,
      code: "",
      codeState: null,
      jwt: "",
    };
  },
  created() {
    this.loggedInBefore = localStorage.getItem('loggedInBefore');
  },
  methods: {
    resetPassword () {
      this.$router.push({ path: "/reset" });
    },
    async login() {
      this.loggingIn = false;
      try {
        let response = await this.$http.post(
          this.$store.state.apiURL + "/account/login",
          this.form
        );
        // if (
        //   response.data.jwt == undefined ||
        //   response.data.jwt == null ||
        //   response
        // ) {
        //   this.loggingIn = false;
        //   this.showModal = true;
        //   this.jwt = response.data.jwt;
        // } else {
          //console.log("logged in and token is");
         // console.log(response.data.jwt);
        this.$store.commit("setToken", {
          JWT: response.data.jwt,
        });
        this.$store.commit("setRefreshToken", {
          JWT: response.data.refresh_jwt,
        });
        this.$store.commit("setLoggedInBefore", {
          loggedInBefore: true,
        });
        if (!this.$store.state.admin) {
          console.log("not admin");
          // show error
        } else {
          this.$router.push({ path: "/style" });
        }
        // }
      } catch (error) {
        this.handleError(error);
      }
      this.loggingIn = false;
    },
    checkFormValidity() {
      const valid = this.$refs.formcode.checkValidity();

      this.codeState = valid;

      return valid;
    },
    resetModal() {
      this.code = "";
      this.codeState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.handleCodeSubmit();
    },
    async handleCodeSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      try {
        let response = await this.$http.post(
          this.$store.state.apiURL + "/account/two-factor",
          {
            token: this.code,
          },
          {
            headers: {
              Authorization: "Bearer " + this.jwt,
            },
          }
        );
        this.codeState = true;

        this.$store.commit("setToken", {
          JWT: response.data.jwt,
        });
        this.$store.commit("setRefreshToken", {
          JWT: response.data.refresh_jwt,
        });
        if (!this.$store.state.admin) {
          this.$store.commit("clearState", {});
          // show error
        } else {
          this.$router.push({ path: "/style" });
        }
      } catch (error) {
        console.log("error in logging in");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/theme.scss";

h4 {
  color: #003e69;
}
</style>
